import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../../context/GlobalContext";
import axiosInstance from "../../../services/frappeServices/AxiosInstance";
import "./Events.css";
const ChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    style={{ cursor: "pointer" }}
  >
    <path d="M15 18l-6-6 6-6" />
  </svg>
);
const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    style={{ cursor: "pointer" }}
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);
// Star Rating Component
const StarRating = ({ totalStars, onChange, value }) => {
  const [rating, setRating] = useState(value || 0);
  const [hover, setHover] = useState(0);
  const handleRatingChange = (currentRating) => {
    setRating(currentRating);
    onChange(currentRating);
  };
  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((star, index) => {
        const currentRating = (index + 1) / 10; // Half stars
        return (
          <span
            key={index}
            onClick={() => handleRatingChange(currentRating)}
            onMouseEnter={() => setHover(currentRating)}
            onMouseLeave={() => setHover(rating)}
            className={`star ${currentRating <= (hover || rating) ? "active" : ""
              }`}
          >
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

const EventsPage = () => {
  const [currentView, setCurrentView] = useState("calendar");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.training_event.get_training`
        );

        const transformedEvents = response.data.message.map((event) => ({
          id: event.name,
          title: event.name,
          start: new Date(event.start_time),
          end: new Date(event.end_time),
          status: event.event_status,
          eventDetails: event,
        }));

        setEvents(transformedEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const getEventImage = (eventType) => {
    const imageMap = {
      Workshop:
        "https://cdn.vectorstock.com/i/1000x1000/99/34/training-icon-design-vector-7649934.webp",
      Webinar:
        "https://cdn.vectorstock.com/i/1000x1000/99/34/training-icon-design-vector-7649934.webp",
      Conference:
        "https://cdn.vectorstock.com/i/1000x1000/99/34/training-icon-design-vector-7649934.webp",
      Training:
        "https://cdn.vectorstock.com/i/1000x1000/99/34/training-icon-design-vector-7649934.webp",
    };

    return (
      imageMap[eventType] ||
      "https://cdn.vectorstock.com/i/1000x1000/99/34/training-icon-design-vector-7649934.webp"
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", {
      weekday: "short",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const EventCard = ({ event, onViewDetails }) => {
    const statusClassMap = {
      Scheduled: "status-scheduled",
      Completed: "status-completed",
      Cancelled: "status-cancelled",
    };

    return (
      <div className="event-card">
        <div className="event-card-image-container">
          <img
            src={getEventImage(event.eventDetails.type)}
            alt={event.title}
            className="event-card-image"
          />
          <div
            className={`event-status ${statusClassMap[event.status] || "status-default"
              }`}
          >
            {event.status}
          </div>
        </div>

        <div className="event-card-content">
          <h3 className="event-card-title">{event.title}</h3>

          <div className="event-card-details">
            <div className="event-detail">
              <i className="icon-calendar"></i>
              <span> From : {formatDate(event.start)}</span>
            </div>

            <div className="event-detail">
              <i className="icon-calendar"></i>
              <span> To : {formatDate(event.end)}</span>
            </div>

            <div className="event-detail">
              <i className="icon-clock"></i>
              <span>Duration: {calculateDuration(event.start, event.end)}</span>
            </div>
          </div>

          <button
            onClick={() => onViewDetails(event)}
            className="event-card-button"
          >
            View Event Details
          </button>
        </div>
      </div>
    );
  };

  const calculateDuration = (start, end) => {
    const duration = (new Date(end) - new Date(start)) / (1000 * 60 * 60);
    return `${duration.toFixed(1)} hours`;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading events...</p>
        </div>
      );
    }

    if (currentView === "calendar") {
      return (
        <Calendar
          events={events}
          onEventClick={(event) => setSelectedEvent(event)}
        />
      );
    }

    return (
      <div className="events-grid">
        {events.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            onViewDetails={setSelectedEvent}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="events-page-container">
      <div className="events-view-switcher">
        <button
          onClick={() => setCurrentView("calendar")}
          className={`view-button ${currentView === "calendar" ? "active" : ""
            }`}
        >
          Calendar View
        </button>
        <button
          onClick={() => setCurrentView("cards")}
          className={`view-button ${currentView === "cards" ? "active" : ""}`}
        >
          Poster View
        </button>
      </div>

      {renderContent()}

      {selectedEvent && (
        <EventDetailModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

const EventDetailModal = ({ event, onClose }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  // State for star ratings
  const [engagementLevel, setEngagementLevel] = useState(0);
  const [qualityOfSpeaker, setQualityOfSpeaker] = useState(0);
  const [howSatisfied, setHowSatisfied] = useState(0);
  const [easeOfRegistration, setEaseOfRegistration] = useState(0);
  const [punctualityAndSchedule, setPunctualityAndSchedule] = useState(0);
  const { currentCustomer } = useGlobalContext();
  const { customerName } = useGlobalContext();
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.training_event.get_training_field`,
          { event: event.title }
        );
        setEventDetails(response.data.message);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching event details:", error);
        setLoading(false);
      }
    };
    fetchEventDetails();
  }, [event]);
  if (loading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <p>Loading event details...</p>
        </div>
      </div>
    );
  }
  const htmlToPlainText = (htmlString) => {
    if (!htmlString) return "";
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    // Extract text content, replacing multiple spaces and trimming
    return tempDiv.textContent.replace(/\s+/g, " ").trim();
  };
  const shouldDisplayValue = (value) => {
    return value != null && value !== "" && value.trim() !== "";
  };
  const handleConfirmRegistration = async () => {
    // Check if all ratings are filled
    if (event.status === "Completed") {
      const allRatingsSet = [
        engagementLevel,
        qualityOfSpeaker,
        howSatisfied,
        easeOfRegistration,
        punctualityAndSchedule
      ].every(rating => rating > 0);

      // Check if comment is not empty
      const isCommentFilled = comment.trim() !== "";

      // Validate all fields are filled
      if (!allRatingsSet || !isCommentFilled) {
        alert("Please fill in all rating fields and provide a comment before submitting.");
        return;
      }
    }

    try {
      let payload = {
        event: eventDetails.name,
        customer: currentCustomer,
        customer_name: customerName,
      };

      // If event is Completed, add additional details
      if (event.status === "Completed") {
        payload = {
          ...payload,
          comment,
          engagement_level: engagementLevel,
          quality_of_speaker: qualityOfSpeaker,
          how_satisfied: howSatisfied,
          ease_of_registration: easeOfRegistration,
          punctuality_and_schedule: punctualityAndSchedule,
        };
      }

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.training_event.post_customer`,
        payload
      );

      alert(response.data.message);
      onClose();
    } catch (error) {
      console.error("Error confirming registration:", error);
      alert("Failed to confirm registration.");
    }
  };
  if (loading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <p>Loading event details...</p>
        </div>
      </div>
    );
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        <div className="modal-scroll-container">
          <h2>{eventDetails.event_name}</h2>
          <div className="event-details">
            {shouldDisplayValue(eventDetails.name) && (
              <div className="detail-row">
                <span className="detail-label">Event Name:</span>
                <span className="detail-value">{eventDetails.name}</span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.training_program) && (
              <div className="detail-row">
                <span className="detail-label">Training Program:</span>
                <span className="detail-value">
                  {eventDetails.training_program}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.type) && (
              <div className="detail-row">
                <span className="detail-label">Type:</span>
                <span className="detail-value">{eventDetails.type}</span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.trainer_name) && (
              <div className="detail-row">
                <span className="detail-label">Trainer Name:</span>
                <span className="detail-value">
                  {eventDetails.trainer_name}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.trainer_email) && (
              <div className="detail-row">
                <span className="detail-label">Trainer Email:</span>
                <span className="detail-value">
                  {eventDetails.trainer_email}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.contact_number) && (
              <div className="detail-row">
                <span className="detail-label">Contact Number:</span>
                <span className="detail-value">
                  {eventDetails.contact_number}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.custom_location_type) && (
              <div className="detail-row">
                <span className="detail-label">Custom Location Type:</span>
                <span className="detail-value">
                  {eventDetails.custom_location_type}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.location) && (
              <div className="detail-row">
                <span className="detail-label">Location:</span>
                <span className="detail-value">{eventDetails.location}</span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.custom_address_details) && (
              <div className="detail-row">
                <span className="detail-label">Custom Address Details:</span>
                <span className="detail-value">
                  {eventDetails.custom_address_details}
                </span>
              </div>
            )}
            {shouldDisplayValue(htmlToPlainText(eventDetails.introduction)) && (
              <div className="detail-row">
                <span className="detail-label">Introduction:</span>
                <span className="detail-value">
                  {htmlToPlainText(eventDetails.introduction)}
                </span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.start_time) && (
              <div className="detail-row">
                <span className="detail-label">Start Time:</span>
                <span className="detail-value">{eventDetails.start_time}</span>
              </div>
            )}
            {shouldDisplayValue(eventDetails.end_time) && (
              <div className="detail-row">
                <span className="detail-label">End Time:</span>
                <span className="detail-value">{eventDetails.end_time}</span>
              </div>
            )}
            {event.status === "Completed" && (
              <div className="feedback-section">
                <h3>Event Feedback</h3>
                <div className="rating-row">
                  <span>Engagement Level:</span>
                  <StarRating
                    totalStars={10}
                    onChange={setEngagementLevel}
                    value={engagementLevel}
                  />
                </div>
                <div className="rating-row">
                  <span>Quality of Speaker:</span>
                  <StarRating
                    totalStars={10}
                    onChange={setQualityOfSpeaker}
                    value={qualityOfSpeaker}
                  />
                </div>
                <div className="rating-row">
                  <span>Overall Satisfaction:</span>
                  <StarRating
                    totalStars={10}
                    onChange={setHowSatisfied}
                    value={howSatisfied}
                  />
                </div>
                <div className="rating-row">
                  <span>Ease of Registration:</span>
                  <StarRating
                    totalStars={10}
                    onChange={setEaseOfRegistration}
                    value={easeOfRegistration}
                  />
                </div>
                <div className="rating-row">
                  <span>Punctuality and Schedule:</span>
                  <StarRating
                    totalStars={10}
                    onChange={setPunctualityAndSchedule}
                    value={punctualityAndSchedule}
                  />
                </div>
                <div className="comment-section">
                  <label htmlFor="feedback-comment">Additional Comments:</label>
                  <textarea
                    id="feedback-comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Share your thoughts about the event..."
                    rows="4"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              className="confirm-registration-btn"
              onClick={handleConfirmRegistration}
            >
              {event.status === "Scheduled"
                ? "Confirm Registration"
                : "Submit Feedback"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { currentCustomer } = useGlobalContext();
  const { customerName } = useGlobalContext();
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.training_event.get_training`
        );
        console.log("the current customer is", currentCustomer);
        console.log("the custpmer is ", customerName);
        const transformedEvents = response.data.message.map((event) => ({
          id: event.name,
          title: event.name,
          start: new Date(event.start_time),
          end: new Date(event.end_time),
          status: event.event_status, // Explicitly capture the status
        }));
        setEvents(transformedEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);
  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };
  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };
  const previousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };
  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };
  const isDateInRange = (date, eventStart, eventEnd) => {
    const normalizedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const normalizedStart = new Date(
      eventStart.getFullYear(),
      eventStart.getMonth(),
      eventStart.getDate()
    );
    const normalizedEnd = new Date(
      eventEnd.getFullYear(),
      eventEnd.getMonth(),
      eventEnd.getDate()
    );
    return normalizedDate >= normalizedStart && normalizedDate <= normalizedEnd;
  };
  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };
  const getEventStatusColor = (status) => {
    switch (status) {
      case "Scheduled":
        return "event-scheduled"; // Use custom CSS class
      case "Completed":
        return "event-completed";
      case "Cancelled":
        return "event-cancelled";
      default:
        return "event-default";
    }
  };
  const generateCalendarGrid = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getFirstDayOfMonth(currentDate);
    const weeks = [];
    let days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <td key={`prev-${i}`} className="calendar-cell prev-month">
          {getDaysInMonth(
            new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
          ) -
            firstDayOfMonth +
            i +
            1}
        </td>
      );
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const dayEvents = events.filter((event) =>
        isDateInRange(date, event.start, event.end)
      );
      days.push(
        <td key={day} className="calendar-cell">
          <span
            className={
              date.toDateString() === new Date().toDateString()
                ? "current-day"
                : ""
            }
          >
            {day}
          </span>
          {dayEvents.length > 0 && (
            <div className="event-container">
              <div className="event-list">
                {dayEvents.slice(0, 3).map((event) => (
                  <div
                    key={event.id}
                    className={`event ${getEventStatusColor(event.status)}`}
                    onClick={() => handleEventClick(event)}
                  >
                    {event.title}
                  </div>
                ))}
                {dayEvents.length > 3 && (
                  <div className="event-overflow">+{dayEvents.length - 3}</div>
                )}
              </div>
            </div>
          )}
        </td>
      );
      if (days.length === 7) {
        weeks.push(<tr key={day}>{days}</tr>);
        days = [];
      }
    }
    const remainingCells = 7 - days.length;
    if (remainingCells < 7) {
      for (let i = 1; i <= remainingCells; i++) {
        days.push(
          <td key={`next-${i}`} className="calendar-cell next-month">
            {i}
          </td>
        );
      }
      weeks.push(<tr key="last-week">{days}</tr>);
    }
    return weeks;
  };
  const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  if (loading) {
    return <div>Loading events...</div>;
  }
  return (
    <div className="calendar-wrapper">
      <div className="calendar-header">
        <div className="calendar-navigation">
          <button onClick={previousMonth}>
            <ChevronLeft />
          </button>
          <span className="current-month">
            {currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button onClick={nextMonth}>
            <ChevronRight />
          </button>
        </div>
        <div className="view-options">
          <button
            className="today-button"
            onClick={() => setCurrentDate(new Date())}
          >
            Today
          </button>
        </div>
      </div>
      <table className="calendar-table">
        <thead>
          <tr>
            {weekDays.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>{generateCalendarGrid()}</tbody>
      </table>
      {selectedEvent && (
        <EventDetailModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};
export default EventsPage;
