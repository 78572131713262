export const MenuList = [
    {
        title: 'home',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-025-dashboard"></i>,
        content: [
            {
                title: 'dashboard',
                to: 'dashboard',
            },
            {
                title: 'promotion',
                to: 'promotion',
            },
            {
                title: 'customerStatement',
                to: 'customer-statement',
            },
            {
                title: 'salesRequestForQuotation',
                to: 'SalesRequestForQutation',
            },
            {
                title: 'hikvisionDiscountVoucher',
                to: 'DiscountVoutcher',
            },
            {
                title: 'AAAQDiscountVoucher',
                to: 'AAAQ_Discount_Voucher',
            },
            {
                title: 'customerTarget',
                to: 'CustomerTarget',
            },
            {
                title: 'salesInvoice',
                to: 'SalesInvoice',
            },
            {
                title: 'deliveryNote',
                to: 'DeliveryNote',
            },
            {
                title: 'salesOrder',
                to: 'SalesOrder',
            },
            {
                title: 'quotations',
                to: 'Quotations',
            },
            {
                title: 'prePayment',
                to: 'pre-payment',
            },
            {
                title: 'Events',
                to: 'Events',
            },
        ],
    },
];