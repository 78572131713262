import React, { useState, useEffect } from 'react';
import { Tab, Nav, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getTopSalesItems } from '../../../../services/frappeServices/api/api';
import { useGlobalContext } from '../../../../context/GlobalContext';

// Images
import pic1 from './../../../../images/comapny/1.png';

const translations = {
    en: {
        topPurchasingItems: "Top Purchasing Items",
        details: "Details",
        cancel: "Cancel",
        amount: "Amount",
        quantity: "Quantity",
        viewMore: "View more",
        loading: "loading ..."
    },
    ar: {
        topPurchasingItems: "أفضل العناصر المشتراة",
        details: "التفاصيل",
        cancel: "إلغاء",
        amount: "المبلغ",
        quantity: "الكمية",
        viewMore: "عرض المزيد",
        loading: "جار التحميل ..."
    }
};

const FeaturedCompanies = () => {
    const { currentCustomer } = useGlobalContext();
    const [allData, setAllData] = useState([]); // Store all sales data
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
    const t = translations[language];

    const currentYear = new Date().getFullYear();
    const years = [currentYear - 2, currentYear - 1, currentYear];

    // Fetch sales data
    const fetchSalesData = async () => {
        setLoading(true);
        try {
            const response = await getTopSalesItems(currentCustomer);
            console.log("API Response:", response); // Debugging
            setAllData(response.message || []);
        } catch (error) {
            console.log("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSalesData();
    }, [currentCustomer]);

    // Filter data based on selectedYear
    const filteredData = allData.filter(item => item.year === selectedYear);

    return (
        <Tab.Container activeKey={selectedYear.toString()}>
            <div className="card fix-top-selling-item">
                <div className="card-header border-0 pb-0 d-flex flex-wrap align-items-center">
                    <h4 className="fs-20 mb-1">{t.topPurchasingItems}</h4>
                    
                    <div className="card-action coin-tabs mt-3 mt-sm-0">
                        {/* Year Tabs */}
                        <Nav className="nav nav-tabs me-2">
                            {years.map((year) => (
                                <Nav.Item key={year}>
                                    <Nav.Link 
                                        eventKey={year.toString()} 
                                        onClick={() => {
                                            console.log(`Filtering for Year: ${year}`); // Debugging
                                            setSelectedYear(year);
                                        }} 
                                    >
                                        {year}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>

                        {/* Dropdown Menu */}
                       
                    </div>
                </div>

                <div className="card-body pt-3 featured-scroll loadmore-content dlab-scroll height370">
                    <Tab.Content>
                        <Tab.Pane eventKey={selectedYear.toString()} key={selectedYear}>
                            <div className="row">
                                {loading ? (
                                    <p>{t.loading}</p>
                                ) : (
                                    filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <div className="col-xl-6 col-sm-6 mt-4" key={index}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-${index}`}>
                                                            <div>
                                                                <h5 style={{ color: 'white' }}>{item.item_name}</h5>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="d-flex mb-2">
                                                        <img src={item.image ? `${process.env.REACT_APP_BASE_URL}/${item.image}` : pic1} alt="" style={{ height: '50px' }} />
                                                        <div className="ms-3 featured">
                                                            <h5 className="fs-18 mb-2">{item.item_code}</h5>
                                                            <h6 className="fs-16 mb-1">{t.amount}: {item.base_amount.toLocaleString()}</h6>
                                                            <h6 className="fs-16 mb-1">{t.quantity}: {item.quantity.toLocaleString()}</h6>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No sales data available for {selectedYear}</p>
                                    )
                                )}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>

                 
            </div>
        </Tab.Container>
    );
};

export default FeaturedCompanies;//
