import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import CanvasChart2 from "./TabChart/CanvasChart2";
import { getCustomTargetStatistics } from "../../../../services/frappeServices/api/api";
import { useGlobalContext } from "../../../../context/GlobalContext";

const CanvasChartTab = () => {
  const currentYear = new Date().getFullYear();
  const pastYears = [currentYear - 2, currentYear - 1, currentYear];

  const { currentCustomer } = useGlobalContext();
  const [year, setYear] = useState(currentYear);
  const [yearsData, setYearsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");

  const translations = {
    en: {
      target: "Target",
      actual: "Actual",
      variance: "Variance",
      loading: "Loading data...",
      noData: "No data available",
    },
    ar: {
      target: "الهدف",
      actual: "الفعلي",
      variance: "الفرق",
      loading: "جار تحميل البيانات...",
      noData: "لا توجد بيانات متاحة",
    },
  };
  const t = translations[language];

  // Fetch data when year or customer changes
  useEffect(() => {
    if (!currentCustomer) return; // Avoid fetching data if no customer is selected

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getCustomTargetStatistics(year, currentCustomer);
        setYearsData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [year, currentCustomer]);

  return (
    <Tab.Container defaultActiveKey={`year-${currentYear}`}>
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="fs-20 mb-1">{t.target}</h4>
          <div className="card-action coin-tabs mt-3 mt-sm-0">
            <div className="year-input">
              <Nav className="nav nav-tabs" role="tablist">
                {pastYears.map((yr) => (
                  <Nav.Item key={yr}>
                    <Nav.Link
                      eventKey={`year-${yr}`}
                      onClick={() => setYear(yr)}
                      active={year === yr}
                    >
                      {yr}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="pb-4 d-flex flex-wrap">
            <span className="me-sm-5 me-3 font-w500">
              <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                <rect width="13" height="13" fill="#f73a0b" />
              </svg>
              {t.target}
            </span>
            <span className="ms-sm-5 ms-3 font-w500 mr-5">
              <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                <rect width="13" height="13" fill="#4BC0C0" />
              </svg>
              {t.actual}
            </span>
            <span className="me-sm-5 me-3 font-w500">
              <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                <rect width="13" height="13" fill="#FFCE56" />
              </svg>
              {t.variance}
            </span>
          </div>

          <Tab.Content>
            {pastYears.map((yr) => (
              <Tab.Pane eventKey={`year-${yr}`} key={yr}>
                {loading ? (
                  <p>{t.loading}</p>
                ) : yearsData ? (
                  <CanvasChart2 className="chartjs" dataActive={yr === currentYear ? 2 : yr === currentYear - 1 ? 1 : 0} year={yr} statisticsData={yearsData} />
                ) : (
                  <p>{t.noData}</p>
                )}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default CanvasChartTab;

