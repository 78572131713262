import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import axiosInstance from '../../../services/frappeServices/AxiosInstance';
import { useGlobalContext } from '../../../context/GlobalContext';
import "./content.css";
import "./Srfqsyle.css";
import "./fixModel.css";
import "./addsrfq.css";
const translations = {
  en: {
    Success: "Success",
    salesRequestForQuotationSuccessfully: "Sales Request For Quotation Successfully",
    filter: "Filter",
    to: "To",
    salesRequestForQuotation: "Sales Request For Quotation",
    addNew: "Add New",
    review: "Review",
    itemCode: "Item Code",
    itemName: "Item Name",
    quantity: "Quantity",
    price: "Price",
    totalPrice: "Total Price",
    edit: "Edit",
    remove: "Remove",
    srfqDetails: "SRFQ Details",
    name: "Name",
    customer: "Customer",
    date: "Date",
    status: "Status",
    items: "Items",
    image: "Image",
    rate: "Rate",
    total: "Total",
    netTotal: "Net Total",
    totalTaxes: "Total Taxes",
    grandTotal: "Grand Total",
    print: "Print",
    close: "Close",
    save: "Save",
    loading: "Loading...",
    noItemsFound: "No items found",
    noItemsAvailable: "No items available for review",
    priceNotAvailable: "Price not available",
    reviewSalesQuotation: "Review Sales Quotation",
    itemDetails: "Item Details"
  },
  ar: {
    Success: "نجح",
    salesRequestForQuotationSuccessfully: "تمت بنجاح",
    filter: "فلتر",
    to: "إلى",
    salesRequestForQuotation: "طلب عرض أسعار",
    addNew: "أضف جديد",
    review: "مراجعة",
    itemCode: "كود العنصر",
    itemName: "اسم العنصر",
    quantity: "كمية",
    price: "السعر",
    totalPrice: "السعر الكلي",
    edit: "تعديل",
    remove: "إزالة",
    srfqDetails: "تفاصيل طلب عرض الأسعار",
    name: "اسم",
    customer: "العميل",
    date: "تاريخ",
    status: "الحالة",
    items: "العناصر",
    image: "صورة",
    rate: "معدل",
    total: "مجموع",
    netTotal: "الصافي الإجمالي",
    totalTaxes: "إجمالي الضرائب",
    grandTotal: " المبلغ الكلي",
    print: "طباعة",
    close: "إغلاق",
    save: "حفظ",
    loading: "جار التحميل...",
    noItemsFound: "لم يتم العثور على عناصر",
    noItemsAvailable: "لا توجد عناصر للمراجعة",
    priceNotAvailable: "السعر غير متاح",
    reviewSalesQuotation: "مراجعة عرض الأسعار",
    itemDetails: "تفاصيل العنصر"
  }
};




const Content = () => {
  const [showModal, setShowModal] = useState(false);
  const { currentCustomer } = useGlobalContext();
  const [editIndex, setEditIndex] = useState(null);
  const [itemCode, setItemCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [qty, setQty] = useState("");
  const [rate, setRate] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [savedSRFQName, setSavedSRFQName] = useState("");
  const [showSRFQDetailsModal, setShowSRFQDetailsModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items_by_category`,
        {
          category: "Project",
          customer: currentCustomer
        }
      );
      if (response.data && response.data.message) {
        setCategories(response.data.message.map(cat => cat.name));
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchItems = async (searchText) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items`,
        {
          params: {
            customer: currentCustomer,
            search_txt: searchText
          }
        }
      );
      if (response.data && response.data.message) {
        setAllItems(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleItemCodeChange = (e) => {
    const newItemCode = e.target.value;
    setItemCode(newItemCode);
    setShowDropdown(true);
    if (newItemCode.length >= 2) {
      fetchItems(newItemCode);
    } else {
      setAllItems([]);
    }
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setItemCode(item.item_code);
    setItemName(item.item_name);
    setRate(categories.includes(item.custom_category) ? "Price not available" : item.price[0].price_list_rate);
    setShowDropdown(false);
  };

  const validateForm = () => {
    if (!itemCode || !itemName || !qty || (!rate && rate !== "Price not available")) {
      setError("All fields are required.");
      return false;
    }
    if (parseFloat(qty) <= 0) {
      setError("Quantity must be greater than 0.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }
    const tableBody = document.querySelector("tbody");
    const totalPrice = rate === "Price not available" ? "Price not available" : (parseFloat(rate) * parseFloat(qty)).toFixed(2);
    if (editIndex !== null) {
      // Editing an existing row - Replace the quantity and rate
      const existingRow = tableBody.rows[editIndex];
      existingRow.cells[1].innerText = itemCode;
      existingRow.cells[2].innerText = itemName;
      existingRow.cells[3].innerText = qty;
      existingRow.cells[4].innerText = rate;
      existingRow.cells[5].innerText = totalPrice;
      setEditIndex(null);  // Clear the edit index
    } else {
      // Adding a new item or updating existing item by increasing the quantity
      const existingRowIndex = Array.from(tableBody.rows).findIndex(row => row.cells[1].innerText === itemCode);
      if (existingRowIndex !== -1) {
        // Update existing row by increasing quantity
        const existingRow = tableBody.rows[existingRowIndex];
        const existingQty = parseFloat(existingRow.cells[3].innerText);
        const newQty = existingQty + parseFloat(qty);
        existingRow.cells[3].innerText = newQty;
        existingRow.cells[5].innerText = (rate === "Price not available" ? "Price not available" : (parseFloat(rate) * newQty).toFixed(2));
      } else {
        // Add new row
        const newRow = tableBody.insertRow();
        newRow.insertCell(0).innerText = tableBody.rows.length; // Row number
        newRow.insertCell(1).innerText = itemCode;
        const itemNameCell = newRow.insertCell(2);
        itemNameCell.innerText = itemName;
        itemNameCell.style.whiteSpace = "nowrap";  // Prevent text wrapping
        itemNameCell.style.maxWidth = "130px";
        itemNameCell.style.overflowX = "auto";
        newRow.insertCell(3).innerText = qty;
        newRow.insertCell(4).innerText = rate;
        newRow.insertCell(5).innerText = totalPrice;

        const editCell = newRow.insertCell(6);
        const editButton = document.createElement("button");
        editButton.className = "btn btn-warning btn-sm";
        editButton.innerText = "Edit";
        editButton.onclick = () => handleEdit(newRow.rowIndex - 1);
        editCell.appendChild(editButton);
        const removeCell = newRow.insertCell(7);
        const removeButton = document.createElement("button");
        removeButton.className = "btn btn-danger btn-sm";
        removeButton.style.backgroundColor = "red";  // Set the background color explicitly
        removeButton.style.borderColor = "red";
        removeButton.innerText = "Remove";
        removeButton.onclick = () => {
          newRow.remove();
          // Update row numbers after removal
          Array.from(tableBody.rows).forEach((row, index) => {
            row.cells[0].innerText = index + 1;
          });
        };
        removeCell.appendChild(removeButton);
      }
    }
    // Reset the form
    setItemCode("");
    setItemName("");
    setQty("");
    setRate("");
    setShowModal(false);
  };
  const handleEdit = (index) => {
    const tableBody = document.querySelector("tbody");
    const row = tableBody.rows[index];

    setItemCode(row.cells[1].innerText);
    setItemName(row.cells[2].innerText);
    setQty(row.cells[3].innerText);
    setRate(row.cells[4].innerText);

    setEditIndex(index);
    setShowModal(true);
  };
  const clearRow = (itemCode) => {
    const tableBody = document.querySelector("tbody");
    const rows = Array.from(tableBody.rows);
    const rowIndex = rows.findIndex(row => row.cells[1].innerText === itemCode);
    if (rowIndex !== -1) {
      tableBody.deleteRow(rowIndex);
    }
  };
  const clearTable = () => {
    const tableBody = document.querySelector("tbody");
    while (tableBody.firstChild) {
      tableBody.removeChild(tableBody.firstChild);
    }
  };
  const handleSaveToAPI = async () => {
    const tableBody = document.querySelector("tbody");
    const items = Array.from(tableBody.rows).map(row => ({
      item_code: row.cells[1].innerText,
      qty: parseFloat(row.cells[3].innerText)
    }));

    // let itemsToRemove = [];
    // let canProceed = true;

    // for (const item of items) {
    //   try {
    //     const stockResponse = await axiosInstance.post(
    //       `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
    //       {
    //         item_code: item.item_code,
    //         customer: currentCustomer
    //       }
    //     );

    //     if (stockResponse.data && stockResponse.data.message) {
    //       const stockBalance = stockResponse.data.message.stock_balance;
    //       if (stockBalance === 0) {
    //         alert(`Stock balance for item ${item.item_code} is 0. Please review your order.`);
    //         itemsToRemove.push(item.item_code);
    //         canProceed = false;
    //         break;
    //       }
    //     }
    //   } catch (error) {
    //     console.error(`Error checking stock for item ${item.item_code}:`, error);
    //     alert(`Failed to check stock for item ${item.item_code}. Please try again.`);
    //     itemsToRemove.push(item.item_code);
    //     canProceed = false;
    //     break;
    //   }
    // }

    // itemsToRemove.forEach(itemCode => clearRow(itemCode));
    // if (!canProceed) {
    //   return;
    // }

    // const updatedItems = Array.from(tableBody.rows).map(row => ({
    //   item_code: row.cells[1].innerText,
    //   qty: parseFloat(row.cells[3].innerText)
    // }));

    // if (updatedItems.length === 0) {
    //   alert("All items have been removed due to stock issues. No request will be sent.");
    //   return;
    // }

    const requestBody = {
      customer: currentCustomer,
      srfq_item: items // Now using items instead of updatedItems
    };

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation`,
        requestBody
      );

      if (response.status === 200 || response.status === 201) {
        setSavedSRFQName(response.data.data.name);
        setShowSuccessModal(true);
        clearTable();
        setShowReviewModal(false); // Close the Review Modal after saving
      } else {
        alert("Failed to save Sales Request For Quotation. Please try again.");
      }
    } catch (error) {
      console.error("Error saving Sales Request For Quotation:", error);
      alert("An error occurred while saving. Please try again.");
    }
  };
  const SuccessModal = ({ show, onClose, onView }) => {
    return (
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t.Success}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t.salesRequestForQuotationSuccessfully}!</p>
          <p>SRFQ {t.name}: {savedSRFQName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {t.close}
          </Button>
          <Button variant="primary" onClick={onView}>
            {t.review}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SRFQDetailsModal = ({ show, onHide, srfqName }) => {
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hidePriceCategories, setHidePriceCategories] = useState([]);
    const { currentCustomer } = useGlobalContext();
    const [sumTotal, setSumTotal] = useState(0);
    const [hasHiddenPrice, setHasHiddenPrice] = useState(false);

    useEffect(() => {
      if (show && srfqName) {
        fetchSRFQDetails(srfqName);
        fetchHidePriceCategories();
      } else {
        resetState();
      }
    }, [show, srfqName]);

    const resetState = () => {
      setModalData(null);
      setLoading(true);
      setError(null);
      setSumTotal(0);
      setHasHiddenPrice(false);
    };

    const fetchHidePriceCategories = async () => {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items_by_category`,
          { category: "Project", customer: currentCustomer }
        );
        if (response.data && response.data.message) {
          setHidePriceCategories(response.data.message.map(item => item.name));
        }
      } catch (error) {
        console.error("Error fetching hide price categories:", error);
      }
    };

    const fetchSRFQDetails = async (name) => {
      setLoading(true);
      setError(null);
      setHasHiddenPrice(false);
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation/${name}`);
        const srfqData = response.data.data;

        const itemsWithCategory = await Promise.all(
          srfqData.srfq_item.map(async (item) => {
            const itemResponse = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Item/${item.item_code}`);
            return { ...item, custom_category: itemResponse.data.data.custom_category };
          })
        );

        setModalData({ ...srfqData, srfq_item: itemsWithCategory });

        let total = 0;
        let hidden = false;

        itemsWithCategory.forEach(item => {
          if (!hidePriceCategories.includes(item.custom_category)) {
            if (item.rate !== "Price not available") {
              total += item.qty * parseFloat(item.rate);
            }
          } else {
            hidden = true;
          }
        });

        setSumTotal(total);
        setHasHiddenPrice(hidden);

      } catch (error) {
        console.error("Error fetching SRFQ details:", error);
        setError("Failed to load SRFQ details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const handleClose = () => {
      onHide();
      resetState();
    };

    // Calculate Net Total, Total Taxes, and Grand Total
    const netTotal = modalData ? modalData.srfq_item.reduce((acc, item) => {
      if (!hidePriceCategories.includes(item.custom_category) && item.rate !== "Price not available") {
        return acc + (item.qty * parseFloat(item.rate));
      }
      return acc;
    }, 0).toFixed(2) : "0.00";

    const totalTaxes = (parseFloat(netTotal) * 0.15).toFixed(2);
    const grandTotal = (parseFloat(netTotal) + parseFloat(totalTaxes)).toFixed(2);

    return (
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t.srfqDetails}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <p>{t.loading}...</p>}
          {error && <p className="text-danger">{error}</p>}
          {modalData && (
            <>
              <p><strong>{t.name}:</strong> {modalData.name}</p>
              <p><strong>{t.customer}:</strong> {modalData.customer}</p>
              <p><strong>{t.date}:</strong> {modalData.date}</p>
              <p><strong>{t.status}:</strong> {modalData.workflow_state}</p>
              <div>
                <h3>{t.items}</h3>
                {modalData.srfq_item && modalData.srfq_item.length > 0 ? (
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>I{t.itemCode}</th>
                        <th>{t.itemName}</th>
                        <th>{t.image}</th>
                        <th>{t.quantity}</th>
                        <th>{t.rate}</th>
                        <th>{t.total}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalData.srfq_item.map((item, index) => (
                        <tr key={index}>
                          <td className="scrollable-text">{item.item_code}</td>
                          <td className="scrollable-text">{item.item_name}</td>
                          <td>
                            {item.image && item.image !== "" ? (
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                alt="Item"
                              />
                            ) : (
                              <img
                                src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                                alt="Default"
                              />
                            )}
                          </td>
                          <td>{item.qty}</td>
                          <td>
                            {hidePriceCategories.includes(item.custom_category)
                              ? "Price not available"
                              : `${item.rate} SAR`}
                          </td>
                          <td>
                            {hidePriceCategories.includes(item.custom_category)
                              ? "Total not available"
                              : `${(item.qty * parseFloat(item.rate)).toFixed(2)} SAR`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>{t.noItemsFound}</p>
                )}
              </div>
              {/* Net Total, Total Taxes, and Grand Total in separate divs */}
              <div className="totals-container">
                <p className="total-item">
                  <strong>{netTotal}:</strong> <span>{netTotal} SAR</span>
                </p>
                <p className="total-item">
                  <strong>{t.totalTaxes}:</strong> <span>{totalTaxes} SAR</span>
                </p>
                <p className="total-item">
                  <strong>{t.grandTotal}:</strong> <span>{grandTotal} SAR</span>
                </p>
                <div className="d-flex justify-content-center"> {/* Center the buttons */}
                  {!modalData.srfq_item.some(item => hidePriceCategories.includes(item.custom_category)) && (
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Sales%20Request%20For%20Quotation&name=${modalData.name}&format=SRFQ%20V00`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="primary" className="mr-2">{t.print}</Button>
                    </a>
                  )}
                  <Button variant="secondary" onClick={handleClose}>{t.close}</Button>
                </div>
              </div>

            </>
          )}
        </Modal.Body>

      </Modal>
    );
  };

  const [reviewDetails, setReviewDetails] = useState(null);
  const [reviewItems, setReviewItems] = useState([]);
  const fetchReviewDetails = async (srfqName) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation/${srfqName}`
      );
      setReviewDetails({
        name: response.data.data.name,
        customer: response.data.data.customer,
        date: response.data.data.date,
      });
    } catch (error) {
      console.error("Error fetching review details:", error);
    }
  };
  const fetchItemDetails = async (itemCode) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Item/${itemCode}`);
      return response.data.data; // Adjust according to your API response structure
    } catch (error) {
      console.error("Error fetching item details:", error);
      return null; // Return null if there's an error
    }
  };
  const getCurrentItems = async () => {
    const tableBody = document.querySelector("tbody");
    const items = await Promise.all(Array.from(tableBody.rows).map(async row => {
      const itemCode = row.cells[1].innerText;
      const itemDetails = await fetchItemDetails(itemCode); // Fetch item details

      return {
        item_code: itemCode,
        item_name: row.cells[2].innerText,
        qty: row.cells[3].innerText,
        rate: row.cells[4].innerText,
        image: itemDetails ? itemDetails.image : null, // Assuming itemDetails has an 'image' field
      };
    }));
    const totalPrice = items.reduce((acc, item) => {
      if (item.rate !== "Price not available") {
        return acc + (parseFloat(item.qty) * parseFloat(item.rate));
      }
      return acc;
    }, 0).toFixed(2);

    setReviewItems(items);
    await fetchReviewDetails(savedSRFQName); // Fetch review details using the saved SRFQ name
    setShowReviewModal(true);
  };

  // ReviewModal remains unchanged but now it will receive items with images
  const ReviewModal = ({ show, onHide, items, details, onSave }) => {
    const totalQuantity = items.reduce((acc, item) => acc + parseFloat(item.qty), 0);
    const totalPrice = items.reduce((acc, item) => {
      if (item.rate !== "Price not available") {
        return acc + (parseFloat(item.qty) * parseFloat(item.rate));
      }
      return acc;
    }, 0).toFixed(2);

    return (
      <Modal show={show} onHide={onHide} centered size="xl"> {/* Set size to 'xl' */}
        <Modal.Header closeButton>
          <Modal.Title>{t.reviewSalesQuotation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {details && (
            <div>
              <p><strong>{t.name}:</strong> {details.name}</p>
              <p><strong>{t.customer}:</strong> {details.customer}</p>
              <p><strong>{t.data}:</strong> {details.date}</p>
            </div>
          )}
          <table className="custom-table"> {/* Use same class as SRFQDetailsModal */}
            <thead>
              <tr>

                <th>{t.itemCode}</th>
                <th>{t.itemName}</th>
                <th>{t.image}</th>
                <th>{t.quantity}</th>
                <th>{t.rate}</th>
                <th>{t.totalPrice}</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((item, index) => (
                  <tr key={index}>


                    <td className="scrollable-text">{item.item_code}</td>
                    <td>{item.item_name}</td>
                    <td>
                      {item.image && item.image !== "" ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                          alt="Item"
                        />
                      ) : (
                        <img
                          src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                          alt="Default"
                        />
                      )}
                    </td>
                    <td>{item.qty}</td>
                    <td>{item.rate}</td>
                    <td>{item.rate !== "Price not available" ? (parseFloat(item.qty) * parseFloat(item.rate)).toFixed(2) : "Price not available"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">{t.noItemsAvailable}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="mt-3">
            <p><strong>{t.netTotal}:</strong> {totalPrice !== "0.00" ? `${totalPrice} SAR` : "Price not available"}</p>
            <p><strong>{t.totalTaxes}:</strong> {totalPrice !== "0.00" ? `${(parseFloat(totalPrice) * 0.15).toFixed(2)} SAR` : "Price not available"}</p>
            <p><strong>{t.grandTotal}:</strong> {totalPrice !== "0.00" ? `${(parseFloat(totalPrice) * 1.15).toFixed(2)} SAR` : "Price not available"}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>{t.close}</Button>
          <Button variant="primary" onClick={onSave}>{t.save}</Button>
        </Modal.Footer>
      </Modal>
    );
  };


  return (
    <div className="filter cm-content-box box-primary mt-5">
      <div className="content-title">
        <div className="cpa">
          <i className="fas fa-file-word me-2"></i>
          {t.salesRequestForQuotation}
        </div>
        <div>     <Button style={{ margin: "20px" }} variant="primary" onClick={() => setShowModal(true)}>
          {t.addNew}
        </Button>
          <Button style={{ backgroundColor: "green", borderColor: "green" }} onClick={getCurrentItems}>
            {t.review}
          </Button>
        </div>

      </div>

      <div className="cm-content-body form excerpt">
        <div className="card-body">
          <div className="table-responsive fixed-header-table">
            <div id="content_wrapper" className="dataTables_wrapper">
              <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t.itemCode}</th>
                    <th>{t.itemName}</th>
                    <th>{t.quantity}</th>
                    <th>{t.price}</th>
                    <th>{t.totalPrice}</th>
                    <th>{t.edit}</th>
                    <th>{t.remove}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Data rows go here */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>{editIndex !== null ? "Edit Item" : "Add New Item"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="formItemCode" className="mb-3">
              <Form.Label>{t.itemCode}</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="text"
                  value={itemCode}
                  onChange={handleItemCodeChange}
                  placeholder="Enter item code"
                  className="form-control-modern"
                  required
                />
                {isLoading && <div className="spinner-border spinner-border-sm position-absolute end-0 top-50 translate-middle-y me-2" role="status" />}
                {showDropdown && allItems.length > 0 && (
                  <ul className="list-group position-absolute w-100 shadow-sm" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000, backgroundColor: "#f8f9fa" }}>
                    {allItems.map((item) => (
                      <li
                        key={item.item_code}
                        className="list-group-item list-group-item-action"
                        onClick={() => handleSelectItem(item)}
                      >
                        {item.item_code}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Form.Group>
            <Form.Group controlId="formItemName" className="mb-3">
              <Form.Label>{t.itemName}</Form.Label>
              <Form.Control
                type="text"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                placeholder="Enter item name"
                className="form-control-modern"
                required
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="formQty" className="mb-3">
              <Form.Label>{t.quantity}</Form.Label>
              <Form.Control
                type="number"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                placeholder="Enter quantity"
                className="form-control-modern"
                min="1"
                step="1"
                required
              />
            </Form.Group>
            <Form.Group controlId="formRate" className="mb-3">
              <Form.Label>{t.rate}</Form.Label>
              {selectedItem && categories.includes(selectedItem.custom_category) ? (
                <Form.Control
                  type="text"
                  value="Price not available"
                  readOnly
                  className="form-control-modern"
                />
              ) : (
                <Form.Control
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  placeholder="Enter rate"
                  className="form-control-modern"
                  required
                  readOnly
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t.close}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t.save}
          </Button>

        </Modal.Footer>
      </Modal>

      <SuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onView={() => {
          setShowSuccessModal(false);
          setShowSRFQDetailsModal(true);
        }}
      />



      <SRFQDetailsModal
        show={showSRFQDetailsModal}
        onHide={() => setShowSRFQDetailsModal(false)}
        srfqName={savedSRFQName}
      />
      <ReviewModal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        items={reviewItems}
        details={reviewDetails}
        onSave={handleSaveToAPI}
      />

    </div>


  );
};

export default Content; 