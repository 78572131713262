import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { getTotalStatisticsOverYear } from '../../../../services/frappeServices/api/api';
import { useGlobalContext } from '../../../../context/GlobalContext';

const translations = {//
    en: {
        buyingPerformance: "Buying Performance",
        apply: "Apply",
        grandTotal: "Grand Total",
        netTotal: "Net Total",
        discount: "Discount",
    },
    ar: {
        buyingPerformance: "أداء الشراء",
        apply: "تطبيق",
        grandTotal: "الإجمالي الكلي",
        netTotal: "المبلغ الصافي",
        discount: "الخصم",
    }
};

const VacanyCanvasChart1 = loadable(() =>
    pMinDelay(import("./TabChart/VacanyCanvasChart1"), 1000)
);

const VacanyTab = () => {
    const [yearsData, setYearsData] = useState(null);
    const { currentCustomer } = useGlobalContext();
    const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
    const t = translations[language];
    
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 2, currentYear - 1, currentYear];

    const handleApplyClick = async (year) => {
        try {
            console.log("Fetching data for year:", year);
            const response = await getTotalStatisticsOverYear(year, currentCustomer);
            console.log("API Response:", response);
            setYearsData(response.message);
        } catch (error) {
            console.log('Error fetching total years statistics', error);
        }
    };
    
    useEffect(() => {
        handleApplyClick(currentYear);
    }, [currentCustomer]);

    return (
        <Tab.Container defaultActiveKey={currentYear.toString()}>
            <div className="card">
                <div className="card-header border-0 pb-0 flex-wrap">
                    <h4 className="fs-20 font-w500 mb-1">{t.buyingPerformance}</h4>
                    <div className="card-action coin-tabs mt-3 mt-sm-0">
                        <Nav className="nav nav-tabs" role="tablist">
                            {years.map(year => (
                                <Nav.Item key={year}>
                                    <Nav.Link onClick={() => handleApplyClick(year)} eventKey={year.toString()}>
                                        {year}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </div>
                </div>
                <div className="card-body">
                    <div className="pb-4 d-flex flex-wrap">
                        <span className="d-flex align-items-center">
                            <svg className="me-1" width="13" height="13" viewBox="0 0 13 13">
                                <rect width="13" height="13" rx="6.5" fill="#35c556" />
                            </svg>
                            {t.grandTotal}
                        </span>
                        <span className="application d-flex align-items-center">
                            <svg className="me-1" width="13" height="13" viewBox="0 0 13 13">
                                <rect width="13" height="13" rx="6.5" fill="#3f4cfe" />
                            </svg>
                            {t.netTotal}
                        </span>
                        <span className="application d-flex align-items-center">
                            <svg className="me-1" width="13" height="13" viewBox="0 0 13 13">
                                <rect width="13" height="13" rx="6.5" fill="#f34040" />
                            </svg>
                            {t.discount}
                        </span>
                    </div>
                    <Tab.Content>
                        {years.map(year => (
                            <Tab.Pane key={year} eventKey={year.toString()}>
                                <VacanyCanvasChart1 dataActive={year - currentYear + 2} statisticsData={yearsData} />
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </div>
            </div>
        </Tab.Container>
    );
}

export default VacanyTab;

