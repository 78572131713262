export const translations = {
    en: {
        filter: "Filter",
        resetFilter: "Reset Filter",
        status: "Status",
        name: "Name",
        home: "Home",
        dashboard: "Dashboard",
        promotion: "Promotion",
        customerStatement: "Customer Statement",
        salesRequestForQuotation: "Sales Request For Quotation",
        hikvisionDiscountVoucher: "Hikvision Discount Voucher",
        AAAQDiscountVoucher: "AAAQ Discount Voucher",
        customerTarget: "Customer Target",
        salesInvoice: "Sales Invoice",
        deliveryNote: "Delivery Note",
        salesOrder: "Sales Order",
        quotations: "Quotations",
        prePayment: "Pre payment",
        Events: "Events",
    },
    ar: {
        filter: "تصفية",
       
        status: "الحالة",
        deliveryNote: "اشعار التسليم",
        searchbyName: "البحث بالاسم",
        home: "الرئيسية",
        dashboard: "لوحة البيانات",
        promotion: "العروض",
        customerStatement: "كشف حساب ",
        salesRequestForQuotation: "طلب تسعير",
        hikvisionDiscountVoucher: "قسيمة خصم Hikvision",
        AAAQDiscountVoucher: "قسائم خصم AAAQ",
        customerTarget: "اهداف العميل",
        salesInvoice: "فاتورة المبيعات",
        salesOrder: "طلب المبيعات",
        quotations: "عروض الأسعار",
        prePayment: "المدفوعات",
        Events: "الاحداث",
    }
};
